import {
  formatProposalHouse,
  formatToAddHouse,
} from '~/helpers/stayProposal/formatProposalHouse'

import type { StateProposal, StateProposalAddHouseTmp } from '~/types/proposal'

export const useUserProposalB2c = () => {
  const proposalState = useState<StateProposal>('proposal')
  const { $i18n } = useNuxtApp()
  const { currency } = useUser()

  const getFormattedHouses = computed(() => {
    const formattedHouses = [
      ...proposalState.value.automaticProposalHouses.map((proposalHouse) =>
        formatProposalHouse({
          proposalState,
          proposalHouse,
          currency,
          $i18n,
        }),
      ),
      ...proposalState.value.toAddHouses.map((proposalHouseAdded) =>
        formatToAddHouse({ proposalHouseAdded, currency }),
      ),
    ]

    return formattedHouses.filter((f) => !houseToBeDeleted(f.proposalHouseId))
  })

  const getHousesCount = computed(() => getFormattedHouses.value.length)

  const getHousesPayload = computed(() => {
    return getFormattedHouses.value.map((h) => {
      return {
        amount: h.price,
        check_in_date: h.checkInDate,
        check_out_date: h.checkOutDate,
        currency: currency.value,
        house_id: h.id,
        sales_comment: h.salesComment,
      }
    })
  })

  const houseExistInProposal = ({
    checkInDate,
    checkOutDate,
    id,
  }: {
    checkInDate: string
    checkOutDate: string
    id: number
  }) => {
    const findHouse = getFormattedHouses.value?.find((h) => {
      return (
        h.id === id &&
        h.checkInDate === checkInDate &&
        h.checkOutDate === checkOutDate
      )
    })

    return !!findHouse
  }

  const houseToBeDeleted = (proposalHouseId: number) => {
    return proposalState.value.toDeleteHouses.find(
      (h) => h.proposalHouseId === proposalHouseId,
    )
  }

  const addHouseToProposal = (house: StateProposalAddHouseTmp) => {
    if (houseToBeDeleted(house.proposalHouseId)) {
      proposalState.value.toDeleteHouses =
        proposalState.value.toDeleteHouses.filter((t) => t.id !== house.id)
    }

    if (!houseExistInProposal(house))
      proposalState.value.toAddHouses.push(house)
  }

  const removeHouseToProposal = (
    house: Pick<
      StateProposalAddHouseTmp,
      'checkInDate' | 'checkOutDate' | 'id' | 'proposalHouseId'
    >,
  ) => {
    if (houseExistInProposal(house)) {
      proposalState.value.toAddHouses = proposalState.value.toAddHouses.filter(
        (t) => t.id !== house.id,
      )
    }

    if (!houseToBeDeleted(house.proposalHouseId))
      proposalState.value.toDeleteHouses.push(house)
  }

  const updatePrice = ({
    proposalHouseId,
    price,
  }: {
    proposalHouseId: number
    price: number
  }) => {
    const proposalHouse = proposalState.value.automaticProposalHouses.find(
      (h) => h.id === proposalHouseId,
    )
    const proposalHouseAdded = proposalState.value.toAddHouses.find(
      (h) => h.proposalHouseId === proposalHouseId,
    )

    if (proposalHouse) proposalHouse.price[currency.value] = price
    if (proposalHouseAdded) proposalHouseAdded.price = price
  }

  const updateSalesComment = ({
    proposalHouseId,
    comment,
  }: {
    proposalHouseId: number
    comment: string
  }) => {
    const proposalHouse = proposalState.value.automaticProposalHouses.find(
      (h) => h.id === proposalHouseId,
    )
    const proposalHouseAdded = proposalState.value.toAddHouses.find(
      (h) => h.proposalHouseId === proposalHouseId,
    )

    if (proposalHouse) proposalHouse.sales_comment = comment
    if (proposalHouseAdded) proposalHouseAdded.sales_comment = comment
  }

  return {
    addHouseToProposal,
    getFormattedHouses,
    getHousesCount,
    getHousesPayload,
    houseExistInProposal,
    removeHouseToProposal,
    updatePrice,
    updateSalesComment,
  }
}
