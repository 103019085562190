import type { SupportedCurrencies } from 'lc-services/dist/types'
import type { Locale } from 'lc-services/types'
import type {
  StateProposal,
  StateProposalHouse,
  StateProposalAddHouseTmp,
} from '~/types/proposal'

export const formatProposalHouse = ({
  proposalState,
  proposalHouse,
  currency,
  $i18n,
}: {
  proposalState: Ref<StateProposal>
  proposalHouse: StateProposalHouse
  currency: Ref<SupportedCurrencies>
  $i18n: { t: (key: string) => string; locale: Ref<string> }
}) => {
  const { locale } = $i18n

  const house = proposalState.value.housesIncluded.find((h) => {
    return Number(h.id) === Number(proposalHouse.house_id)
  })!

  const photo = proposalState.value.photosIncluded.find(
    (p) => Number(p.id) === Number(house?.firstPhotoId),
  )!

  const destination = proposalState.value.destinationsIncluded.find(
    (d) => Number(d.id) === Number(house?.destinationId),
  )!

  const price = proposalHouse.price[currency.value]

  return {
    checkInDate: proposalHouse.check_in_date,
    checkOutDate: proposalHouse.check_out_date,
    comments: proposalHouse.comments || [],
    destiId: Number(destination?.id),
    destination: destination?.clusterizedName[locale.value as Locale],
    id: Number(house?.id),
    name: house.name,
    photo: photo.url,
    price,
    proposalHouseId: proposalHouse.id,
    salesComment: proposalHouse.sales_comment,
  }
}

export const formatToAddHouse = ({
  proposalHouseAdded,
}: {
  proposalHouseAdded: StateProposalAddHouseTmp
  currency: Ref<SupportedCurrencies>
}) => {
  const price = proposalHouseAdded.price

  return {
    checkInDate: proposalHouseAdded.checkInDate,
    checkOutDate: proposalHouseAdded.checkOutDate,
    comments: [],
    destiId: proposalHouseAdded.destiId,
    destination: proposalHouseAdded.destination,
    id: proposalHouseAdded.id,
    name: proposalHouseAdded.name,
    photo: proposalHouseAdded.photo,
    price,
    proposalHouseId: proposalHouseAdded.proposalHouseId,
    salesComment: proposalHouseAdded.sales_comment,
  }
}
